<template>
    <div class="algo">
<!--        顶部算法--> 
        <div class="algoHeader">
            <div class="centerImg">
                <span v-for="(item, index) in algoList" :key="index" @click="toggleTab(index, item)" :class="activeNow == index ? 'active' : ''"><div><img :src="item.algIconUrl"><p>{{ item.algName }}</p></div></span>
            </div>
        </div>
        <div class="containers">
            <el-card shadow="never" style="border-radius:0.1rem">
                <div><p class="algoIntroduce">算法简介：{{row.algDesc || '无'}}</p></div>
            </el-card>
            <el-card shadow="never" style="border-radius:0.1rem;margin-top: 0.2rem">
                <div class="containers-title" style="margin-bottom:0.12rem">示例/推理文件</div>
                <div class="uploadImg">
                    <div class="exampleUpload">
                        <div class="exampleTitle">
                            <el-tag>文件示例</el-tag>
                            <el-button style="margin-left: 0.2rem" size="small" type="primary" icon="el-icon-upload" @click="getResult(true)">使用示例进行算法推理</el-button>
                            <el-button size="mini" icon="el-icon-download" @click="downLoad">示例集下载</el-button>
                        </div>
                        <img v-if="row.caseType == 1" :src="row.caseFileUrl" alt="示例图片" class="exampleImg">
                        <video v-else style="height: 2.5rem;margin-top: 0.1rem" :src="row.caseFileUrl" controls="controls">您的浏览器不支持 video 标签。</video>
                    </div>
                    <div class="upload">
                        <div class="exampleTitle">
                            <el-tag>自定义上传</el-tag>
                            <el-button style="margin-left: 0.2rem" size="small" type="primary" icon="el-icon-upload" @click="getResult(false)">开始算法推理</el-button>
                        </div>
                        <div v-if="row.caseType == 1">
                            <el-upload
                                style="margin-top: 0.1rem"
                                action=''
                                list-type="picture-card"
                                :http-request='uploadIcon'
                                :file-list="fileIconList"
                                :limit="1">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </div>
                        <el-upload
                            v-else
                            action=''
                            style="margin-top: 0.1rem"
                            class="upload-demo"
                            drag
                            :http-request='uploadTestFileZip'
                            :limit="1"
                            :file-list="fileZipList">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div slot="tip" class="el-upload__tip">仅限视频格式的文件</div>
                        </el-upload>
                    </div>
                </div>
                <div>
                    <div class="containers-title">推理结果</div>
                    <div class="containers-result" v-if="hasResult">
                        <!-- 视频结果 -->
                        <video v-if="row.caseType == 2" :src="'/oss/'+ resultUrl" controls="controls"></video>
                        <!-- 图表结果 -->
                        <!-- <div v-if="type == 2" style="width:100%;display:flex">
                            <div class="containers-result-result">结果{{ }}</div>
                            <div id="chart" style="width:70%;height:400px" ></div>
                        </div> -->
                        <!-- 图片结果 -->
                        <el-image
                            v-if="row.caseType == 1"
                            :src="'/oss/'+ resultUrl"
                        >
                        </el-image>
                    </div>
                    <div v-else class="emptyResult">
                        暂无推理结果
                    </div>
                    <el-dialog
                        title="等待中。。。"
                        :visible.sync="waitVisible"
                        width="30%"
                        :show-close='false'
                        :close-on-click-modal='false'
                        :close-on-press-escape='false'
                    >
                    <div style="width:100%;text-align:center">
                        <i class="el-icon-loading"></i>
                        <span >正在为您进行推理，请稍后</span>
                    </div>
                    </el-dialog>
                </div>
            </el-card>
            <el-card shadow="never" style="border-radius:0.1rem;margin-top:0.24rem">
                <div class="containers-title">推理结果历史记录</div>
                <el-table
                    :data="resultHistory"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    label="序号"
                    align='center'
                    width="50">
                    </el-table-column>
                    <el-table-column
                        prop="algName"
                        align="center"
                        label="算法名称"
                        minWidth="120">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        align="center"
                        minWidth="180"
                    >
                        <template slot-scope="{row}">
                            {{ formatDateTime(new Date(row.createTime)) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        align="center"
                    >
                        <template slot-scope="{row}">
                            <el-button @click="checkResult(row)" type="text">查看结果</el-button>
                            <el-button @click="deleteResult(row)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="height:0.36rem;text-align:right;margin-top:0.12rem"
                    background
                    layout="prev, pager, next,total"
                    :total="total"
                    @current-change="changePage"
                    :current-page="pageNum"
                    :page-size="10"
                >
                </el-pagination>
            </el-card>
            <el-dialog
                title="查看结果"
                :visible.sync="resultPhotVisible"
                width="6rem"
                @close="handleClose"
            >
                <div>
                    <video v-if="resultRow.resultType == 2" :src="'/oss/'+ resultPreviewUrl" style="width:5.6rem" controls="controls"></video>
                    <el-image
                        style="width:5.6rem"
                        v-if="resultRow.resultType == 1"
                        :src="'/oss/'+ resultPreviewUrl"
                    >
                    </el-image>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import api from '@/api/api'
import * as echarts from 'echarts';
export default {
    name: 'algo',
    data() {
        return {
            messageInfo:'111111',
            messageVisible:false,
            total:0,
            resultRow:{},
            resultPreviewUrl:'',
            resultPhotVisible:false,
            waitVisible:false,
            resultUrl:'',
            hasResult:false,
            queryTerms: {},
            type: 1,
            fileId: '',
            caseFileList: [],
            fileZipList: [],
            row: {},
            dialogImageUrl: '',
            dialogVisible: false,
            activeNow: 0,
            algoList: [],
            fileIconList:[],
            resultHistory:[],
            pageNum:1,
        }
    },
    methods: {
        formatDateTime(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            return y + '-' + m + '-' + d+' '+h+':'+minute;
        },
        changePage(val){
            this.pageNum = val
            this.getOsmmAlgResultList()
        },
        target(url) {
            window.location.href = url
        },
        toRoute(route) {
            this.$router.push(route)
        },
        routeToLogin(res){
            if(res.code == 4010002){
                this.$message.warning('未检测到登录，请先登录您的账户')
                this.$router.push({
                    path:'/login',
                    query:{
                        from,
                    }
                })
            }else{
                this.$message.warning(res.msg)
            }
        },
        getData() {
            let params = {
                pageNum: 1,
                pageSize: 100,
                display:1
            }
            api.listOsmmAlgCase(params).then(res => {
                if (res.code == 200) {
                    this.algoList = res.data.list
                    this.algoList.forEach((item) => {
                        let index1 = item.algIconUrl.indexOf('user')
                        item.algIconUrl = '/oss/'+item.algIconUrl.substring(index1, item.algIconUrl.length)
                        let index2 = +item.caseFileUrl.indexOf('user')
                        item.caseFileUrl ='/oss/'+ item.caseFileUrl.substring(index2, item.caseFileUrl.length)
                    })
                    this.row = this.algoList[0]
                } else {
                    this.routeToLogin(res)
                }
            })
        },
        getOsmmAlgResultList(){
            api.listOsmmAlgResult({pageNum:this.pageNum,pageSize:10}).then(res => {
                if(res.code == 200){
                    this.resultHistory = res.data.list
                    this.total = res.data.totalRows
                }
            })
        },
        uploadIcon(file) {
            let params = new FormData()
            params.append('file', file.file)
            params.append('module', 19)
            params.append('isDir', false)
            api.upload(params).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.fileId = res.data[0].id
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        uploadTestFileZip(file){
            let params = new FormData()
            params.append('file',file.file)
            params.append('module',19)
            params.append('isDir',false)
            api.upload(params).then(res => {
                if (res.code == 200) {
                    this.$message.success('上传成功')
                    this.fileId = res.data[0].id
                } else {
                    this.$message.warning('上传文件失败')
                }
            })
        },
        getResult(value) {
            let check = true
            // 推理
            let params
            if (value) {
                params = {
                    fileId: this.row.caseFile,
                    algId: this.row.id
                }
            } else {
                if(this.fileId){
                    params = {
                        fileId: this.fileId,
                        algId: this.row.id
                    }
                }else{
                    check = false
                    this.$message.warning('请先上传文件再推理')
                }
            }
            if(check){
                this.waitVisible = true
                api.addOsmmAlgResult(params).then(res => {
                    this.waitVisible = false
                    if (res.code == 200 && res.data) {
                        this.$message.success('推理成功')
                        if(res.data.result){
                            let resultUrl = JSON.parse(res.data.result).resultFile
                            let params = new FormData()
                            params.append('fileId',resultUrl)
                            api.getUrl(params).then(res => {
                                this.resultUrl = res.data
                                let index = this.resultUrl.indexOf('user')
                                this.resultUrl =this.resultUrl.substring(index, this.resultUrl.length)
                                this.hasResult = true
                            })
                            this.getOsmmAlgResultList()
                        }
                    } else {
                        this.routeToLogin(res)
                    }
                })
                .catch(err=>{
                    this.waitVisible = false
                })
            }
        },
        downLoad() {
            if (this.row.caseType == 1) {
                this.downloadIamge(this.row.caseFileUrl, '示例文件')
            } else {
                fetch(this.row.caseFileUrl).then(res => res.blob()).then(blob => {
                    const a = document.createElement('a');
                    document.body.appendChild(a)
                    a.style.display = 'none'
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = '示例文件.mp4';
                    a.click();
                    document.body.removeChild(a)
                    window.URL.revokeObjectURL(url);
                });

            }
        },
        downloadIamge(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                let url = canvas.toDataURL("image/png/jpg"); //得到图片的base64编码数据

                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
        },
        drawChart(data){
            let myChart = echarts.init(document.getElementById('chart'));
            const gaugeData = [
                {
                    value: 78,
                    name: '结果准确率',
                    title: {
                        offsetCenter: ['0%', '-20%']
                    },
                    detail: {
                        valueAnimation: true,
                        offsetCenter: ['0%', '20%']
                    }
                },
            ];
            let option = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 90,
                        z:999,
                        clockwise:false,
                        endAngle: -270,
                        radius: '70%',
                        center:['50%','50%'],
                        pointer: {
                            show: false
                        },
                        progress: {
                            show: true,
                            overlap: false,
                            roundCap: true,
                            clip: false,
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#464646'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 28
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            distance: 50
                        },
                        data: gaugeData,
                        title: {
                            fontSize: 24
                        },
                        detail: {
                            width: 60,
                            height: 28,
                            fontSize: 20,
                            color: 'auto',
                            borderColor: 'auto',
                            borderRadius: 20,
                            borderWidth: 1,
                            formatter: '{value}%'
                        }
                    }
                ]
            };
            myChart.setOption(option);
        },
        GetFileExt(filepath) {
            if (filepath !== '') {
                let pos = '.' + filepath.replace(/.+\./, '');
                return '文件' + pos;
            }
        },
        toggleTab(index, row) {
            this.activeNow = index
            this.row = row
            this.hasResult = false
        },
        handleRemove(file, fileList) {
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        checkResult(row){
            this.resultRow = row
            let params = new FormData()
            // const file = JSON.parse(row.result).resultFile
            // params.append('fileId',file)
            params.append('fileId',row.fileId)
            api.getUrl(params).then(res => {
                if(res.code == 200){
                    this.resultPreviewUrl = res.data
                    this.resultPhotVisible = true
                    let index = this.resultPreviewUrl.indexOf('user')
                    this.resultPreviewUrl =this.resultPreviewUrl.substring(index, this.resultPreviewUrl.length)
                    // console.log(this.resultPreviewUrl)
                }
            })
        },
        deleteResult(row){
            this.$confirm('确定删除该数据？, 是否继续?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteOsmmAlgResultById(row.id).then(res => {
                    if(res.code == 200){
                        if(res.data == 1){
                            this.$message.success('删除成功')
                        }
                        this.getOsmmAlgResultList()
                    }
                })
            }).catch(() => {
            });
        },
        handleClose(){
            this.resultPhotVisible = false
        },
    },
    mounted(){
        this.getData()
        this.getOsmmAlgResultList()
        this.messageVisible = true
    }
}
</script>
<style lang="scss" scoped>
.algoIntroduce{
    font-size: 0.14rem;
    line-height: 0.26rem;
}
.emptyResult{
    width: 100%;
    height: 2.5rem;
    font-size: 18px;
    font-weight: 500;
    color:#333;
    text-align: center;
    padding-top:1.5rem;
}
.algo {
    width: 100%;
    background-color: #E8ECEF!important;
    font-size: 0.14rem;
    .containers {
        width: 80%;
        margin: 0 auto;
        //padding: 10px;
        padding: 0.1rem 0.1rem 0.5rem 0.1rem;
        .uploadImg {
            height: 3.6rem;
            border-bottom: 1px dashed #dedede;
            .exampleUpload {
                width: 50%;
                height: 100%;
                float: left;
                text-align: center;
                overflow: hidden;
                // .exampleTitle {
                //     //width: 30%;
                // }
                .exampleImg {
                    margin-top: 10px;
                    height: 2.5rem;
                }
            }
            .upload {
                width: 50%;
                height: 100%;
                margin-left: 50%;
                text-align: center;
            }
        }
        &-title{
            height: 0.4rem;
            line-height: 0.4rem;
            font-weight: 600;
            color:#333;
        }
        &-result{
            width: 100%;
            height: 420px;
            display: flex;
            justify-content:center;
            margin:20px 0;
            // &-video{

            // }
            &-result{
                width: 40%;
                height: 360px;
                margin-top:20px;
                border-right:1px dashed #ccc;
            }
        }
    }
    .nav {
        background-color: #222;
        border-color: transparent;
        position: fixed;
        top: 0;
        height: 0.5rem;
        width: 100%;
        z-index: 100;
        .container {
            width: 68%;
            margin: 0 auto;
            height: 100%;
            .navbar-header {
                color: #caced2;
                font-size: 0.18rem;
                font-weight: 500;
                height: 100%;
                .index {
                    display: inline-block;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    cursor: pointer;
                }
                .navbar-right {
                    float: right;
                    .right {
                        font-size: 0.16rem;
                        margin-left: 0.2rem;
                        display: inline-block;
                        height: 0.5rem;
                        line-height: 0.5rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .algoHeader {
        height: 1.1rem;
        width: 100%;
        background-color: #0080D4;
        padding-top: 0.3rem;
        color: #fff;
        .centerImg {
            width: 70%;
            margin: 0 auto;
            position: relative;
            span:hover {
                background-color: #1760918e;
            }
            .active {
                background-color: #1760918e;
            }
            span {
                float: left;
                width: 12.5%;
                height: 1rem;
                cursor: pointer;
                text-align: center;
                p {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                div {
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;
                }
                img {
                    margin-top: 0.1rem;
                    height: 0.6rem;
                }
            }
        }
    }
    .bookFooter {
        background-color: #222;
        border-color: transparent;
        position: fixed;
        bottom: 0;
        height: 0.5rem;
        width: 100%;
        .container {
            width: 70%;
            margin: 0 auto;
            height: 100%;
            text-align: center;
            font-size: 0.05rem;
            color: rgba(255, 255, 255, 0.3);
            p {
                display: inline-block;
                height: 0.5rem;
                line-height: 0.5rem;
                font-weight: 100;
                span {
                    cursor: pointer;
                    color: #337ab7;
                }
            }
        }
    }
}

</style>
<style lang="scss">
.algo {
    .el-card {
        box-shadow: 3px 3px 0 0 #e7dede;
    }
    .el-upload--picture-card {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
    }
    .el-upload-list--picture-card .el-upload-list__item {
        width: 2.5rem;
        height: 2.5rem;
    }
    .el-message{
        min-width: 3rem !important;
        padding:0 0.15rem !important;
    }
    .el-message{
        min-width: 0.3rem !important;
    }
}
</style>
